/* global $,OK,navigateOnUrlFromJS,require */
var OK = window.OK || (window.OK = {});

/** namespace для методов связанных с подарочками */
OK.gifts = (function(window, document) {
    "use strict";

    var clickEvent = "click",
        gfContentId = ".gift-front_cnt",
        form, input,
        resizeEvent = "resize",
        isNewDesign,
        newDesignInput,
        searchingClass = "search-input_searching",
        searchInputActive = "search-input_active",
        processClass = "__process";

    function onWindowResize() {
        // 250 пикселей подобрано на глаз. Визуально выглядит хоршо, даже на iPad
        // с помощью .scroll() пинаем авто-лоадер
        $("#ifDcards").height($(window).height() - 250).scroll();
        $(window).scroll();
    }

    var liveSearchTimeoutId = null, searchInputLastValue, focusInput;

    return {
        /** инициализация леера выбора друга (кому подарить) */
        activateFriendSelectPopup: function() {
            // двигаем леер (через setTimeout для того, чтобы это случилось после позиционирования из GWT)
            setTimeout(function() {
                // 60 пикселей - это как раз под надписью в тулбаре и под иконками
                // полностью видны иконки в тулбаре, баблики, но леер как бы визуально поверх этого.
                $("#popLayerBodyWrapper").css("top","60px");
                onWindowResize();
            }, 1);

            // ресайз окна должен менять размер леера
            $(window).bind(resizeEvent, onWindowResize);
        },

        /** деинициализация леера выбора друга (кому подарить) */
        deActivateFriendSelectPopup: function() {
            $(window).unbind(resizeEvent, onWindowResize);
        },

        /**
         * Активация витрины подарков
         * @param {boolean} liveSearchEnabled
         * @param {number} timeout
         */
        activateGiftsFront: function(liveSearchEnabled, timeout) {
            form = $("#gf-search-form");
            input  = $("#gf-search-input");

            isNewDesign = form.length === 0;
            if (isNewDesign) {
                newDesignInput = document.getElementById("field_qs");
                var tagsContainer = document.getElementById('gifts-tags-container');
                if (tagsContainer) {
                    tagsContainer.addEventListener('click', function(e){
                        if (e.target.classList.contains('js-tag')) {
                            OK.gifts.doSearch(e.target.innerHTML);
                        }
                    }, false);
                }

                return;
            }

            // Инициализация формы поиска
            var lupa   = $("#gf-search-lupa"),
                progress = $("#gf-search-progress"),
                reset  = $("#gf-search-reset");

            // ожидаем активации GWT-хука на форме, это может занять некоторое время.
            OK.onload.addCallback(function(){
                // GWT-хук акивирован, устанавливаем свой onsubmit
                if(form[0]) {
                    form[0].onsubmit = function() {
                        if(searchInputLastValue === input.val().trim()) {
                            $(gfContentId).removeClass(processClass);
                            return false;
                        }

                        $(gfContentId).addClass(processClass);

                        searchInputLastValue = input.val().trim();
                        form.toggleClass(searchInputActive, searchInputLastValue !== "");

                        return this.onsubmitGwt();
                    };
                }
            });

            lupa.bind(clickEvent, function() {
                form.submit();
            });

            if(input.val() && input.val().trim() !== '') {
                form.addClass(searchInputActive);
            }

            var submitForm = function () {
                focusInput = true;
                $(gfContentId).addClass(processClass);
                form.submit();
            };

            // нам нужно отлавливать cut-paste, по этому вешаем на keyup, а не на keypress
            if (liveSearchEnabled) {
                input.on("keyup", function() {
                    var val = input.val().trim();
                    var isEmpty = val === "";
                    
                    if(liveSearchTimeoutId !== null) {
                        clearTimeout(liveSearchTimeoutId);
                    }

                    if(searchInputLastValue !== val) {
                        if (!isEmpty) {
                            form.toggleClass(searchInputActive, !isEmpty);
                            form.addClass(searchingClass);
                            liveSearchTimeoutId = setTimeout(submitForm, timeout);
                        } else {
                            submitForm();
                        }
                    } else {
                        $(gfContentId).removeClass(processClass);
                        form.removeClass(searchInputActive);
                    }

                    return true;
                });
            }

            reset.bind(clickEvent, function() {
                input.val("");
                form.removeClass(searchInputActive);
                form.submit();
                input.focus();
            });

            // вешаем клики на теги
            form.on(clickEvent, '.js-tag', function() {
                OK.gifts.doSearch(this.innerHTML);
            });
        },

        giftsFrontContentLoaded: function() {
            if (isNewDesign) {
                return;
            }

            form.removeClass(searchingClass);
            searchInputLastValue = input.val() ? input.val().trim() : "";
            if(focusInput) {
                setTimeout(function(){input.focus();}, 100);
                focusInput = false;
            }
        },

        /** поиск по примеру */
        doSearch: function(query) {
            OK.logging.logger.success("giftFront", "tagClick", query);
            if (isNewDesign) {
                newDesignInput.value = query;
                newDesignInput.dispatchEvent(new CustomEvent('paste'));
            } else {
                input.val(query);
                form.addClass(searchInputActive);
                form.submit();
            }
        }
    };
})(window, document);

/** Подарки с музыкой */
OK.musicalGifts = (function(){
    "use strict";

    var mgContentId = "#music-gifts-content";
    var i;
    var selectedId;
    var lastPlayedId;
    var sendPresentLink;
    var altLink;
    var playingTrack;
    var friendId;
    var form, input;
    var liveSearchTimeoutId = null,
        searchInputLastValue,
        focusInput;
    var clickEvent = "click";
    var searchingClass = "search-input_searching",
        searchInputActive = "search-input_active",
        processClass = "__process";

    /** Вспомогательный метод для поиска данных для обработки кликов */
    function findAttrValue(target, attrName){
        return findAttrValueX(target, attrName, 0);
    }
    
    function findAttrValueX(target, attrName, deep){
        if(target.getAttribute(attrName)) {
            return {
                value: target.getAttribute(attrName),
                action:target.getAttribute("action")
            };
        } else if( deep < 7 ) {
             return findAttrValueX(target.parentNode, attrName, ++deep);
            
        }else{
        	return undefined;
        }
    }

    function pauseLastPlayed() {
        var element = lastPlayedId && document.getElementById('gt_' + lastPlayedId);

        if (element) {
            var playButton = element.querySelector('.js-track_play');

            if (playButton) {
                playButton.classList.remove('m_portal_track_pause');
            }
        }
    }

    /**
     * @param {HTMLElement} element
     * @return {string}
     */
    function getTrackId(element) {
        return element.getAttribute('data-query');
    }

    /**
     * @return {string}
     */
    function getTrackIds() {
        var element = document.getElementById('music-gifts-content');

        return Array.prototype.slice.call(element.childNodes)
            .map(getTrackId)
            .filter(Boolean)
            .join(',');
    }

    /**
     * @param {string} selectedId
     */
    function play(selectedId) {
        require(['OK/music2/app'], function (app) {
            app.play(Number(selectedId), {
                ctx: 'gifts',
                tids: getTrackIds()
            });
        });
    }

    function onCurrentTrackChange(track) {
        if (!track) {
            return;
        }

        if (track.id !== lastPlayedId || !track.playing) {
            pauseLastPlayed();
        }

        if (track.playing) {
            var element = document.getElementById('gt_' + track.id);

            if (element) {
                var playButton = element.querySelector('.js-track_play');

                if (playButton) {
                    playButton.classList.add('m_portal_track_pause');
                }
            }

            lastPlayedId = track.id;
        }
    }

    /** Глобальный обработчик кликов в леере выбора музыки */
	function clickHandler(e) {
		var target = e.target || e.srcElement;
		i = 0;
		var data = findAttrValue(target, "data-query");
		var sendPresentLinkAttr = findAttrValue(target, "send-present-link");
		var linkOnSelectFriendAttr = findAttrValue(target, "linkOnSelectFriend");
		var altLinkAttr = findAttrValue(target, "alt-link");
        var newConfirmButton = $("#hook_FormButton_button-add");
		if (data === undefined) { return; }
		if (data.action === "select") {
			if (!selectedId) {
				$("#giftMusicIco").removeClass("__disabled");
				$("#sendMP").removeClass("invisible");
				$("#gmSelectedTr").removeClass("invisible");
				$("#sendMPD").addClass("invisible");
                switchButton(newConfirmButton, true);
			}
			if (selectedId !== data.value) {
				$("#gt_" + selectedId).removeClass("__selected");
				if ($("#gt_" + selectedId).attr('data-redesign')){
				    $("#gt_" + selectedId + " div:first").removeClass("__selected");
				}
				selectedId = data.value;
                setSelectedTrackId(selectedId);
				sendPresentLink = sendPresentLinkAttr !== undefined ? sendPresentLinkAttr.value
						: "";
				altLink = altLinkAttr !== undefined ? altLinkAttr.value : "";
				var linkOnSelectFriend = linkOnSelectFriendAttr !== undefined ? linkOnSelectFriendAttr.value : "";
				$("#giftMusicIco").attr("data-query", selectedId);
				$('#mg-track-id').val(selectedId);
				$("#sendMP")
                        .attr("data-query", sendPresentLink)
				        .attr("data-alt", altLink);
				$("#linkOpenSelFriend").attr("data-query", linkOnSelectFriend);
				var selectedNode = $("#gt_" + data.value);
				selectedNode.addClass("__selected");
                if (selectedNode.attr('data-redesign')){
				    $("#gt_" + data.value + " div:first").addClass("__selected");
				}
				$("#gmSelectedTr").html(
						"<span class=\"lp-t\">" +
                        selectedNode.attr("data-artist") +
                        "</span> – " +
                        selectedNode.attr("data-track"));
			}
			if (target.classList.contains('track_play')) {
				play(selectedId, data, target);
			}
		} else if (data.action === "play") {
			play(selectedId, data, target);
		} else if (data.action === "link") {
			var url = data.value;
            input.val('');
            if(target.getAttribute("id") === "sendMP") {
                if (!friendId) {
                    url = target.getAttribute("data-alt");
                }
                if(selectedId) {
                    url += "&st.layer.trackId="+selectedId;
                }
            }
            switchButton(newConfirmButton, false);
			navigateOnUrlFromJS(url);
            OK.historyManager.pushState(url);
            e.preventDefault();
		}
	};

    function switchButton(button, isEnabled) {
        if (isEnabled) {
            button.removeClass("__disabled");
            button.removeAttr('disabled');
        } else {
            button.addClass("__disabled");
            button.attr('disabled', 'disabled');
        }
    }

    function musicGiftsInputChange() {
        var val = input.val().trim();
        form.toggleClass(searchInputActive, val !== "");

        if(liveSearchTimeoutId !== null) {
            clearTimeout(liveSearchTimeoutId);
        }

        if(searchInputLastValue !== val) {
            liveSearchTimeoutId = setTimeout(function() {
                focusInput = true;
                form.submit();
            }, 500);

            $(mgContentId).addClass(processClass);
        } else {
            $(mgContentId).removeClass(processClass);
        }

        return true;
    }

    function setSelectedTrackId(selectedId) {
        $("input#field_trackId[name='st.layer.trackId']").attr('value', selectedId);
    }

    function musicGiftsContentLoaded() {
        form.removeClass(searchingClass);
        searchInputLastValue = input.val() ? input.val().trim() : "";
        focusInput = false;
    }

    return {
        bind: function (id, fid, liveSearchEnabled){
            OK.loader.use('jQuery', function() {
                activate();
            });
            function activate(){
                form = $("#mg-search-form");
                var formAction = $('#giftMSCC').attr('data-query');
                form.attr('action', formAction);
                input = $("#mg-search-input");
                var lupa = $("#mg-search-lupa"),
                    progress = $("#mg-search-progress"),
                    reset  = $("#mg-search-reset");

                if(!id){
                    playingTrack = undefined;
                }
                selectedId = id;
                setSelectedTrackId(selectedId);
                $('#mg-track-id').val(selectedId);
                friendId = fid;
                if(id){
                    var musicIco = $("#giftMusicIco");
                    musicIco.attr("data-query", selectedId);
                    if(selectedId === playingTrack) {
                        $("#gt_" + playingTrack).addClass("__selected");
                        if ($("#gt_" + playingTrack).attr('data-redesign')){
                            $("#gt_" + playingTrack + " div:first").addClass("__selected");
                        }
                        musicIco.addClass("__animated");
                    }
                }
                $("#giftMSCC").click(clickHandler);
                require('OK/music2/app').on('current-track-changed', onCurrentTrackChange);

                musicGiftsContentLoaded();

                if (liveSearchEnabled){
                    input.on("keyup", musicGiftsInputChange);

                    OK.onload.addCallback(function(){
                        // GWT-хук акивирован, устанавливаем свой onsubmit
                        if(form[0]) {
                            form[0].onsubmit = function() {
                                if(searchInputLastValue === input.val().trim()) {
                                    $(mgContentId).removeClass(processClass);
                                    return false;
                                }

                                $(mgContentId).addClass(processClass);
                                form.addClass(searchingClass);

                                searchInputLastValue = input.val().trim();
                                form.toggleClass(searchInputActive, searchInputLastValue !== "");

                                return this.onsubmitGwt();
                            };
                        }
                    });

                    lupa.bind(clickEvent, function() {
                        form.submit();
                    });

                    if(input.val() && input.val().trim() !== '') {
                        form.addClass(searchInputActive);
                    }

                    reset.bind(clickEvent, function() {
                        input.val("");
                        form.removeClass(searchInputActive);
                        form.submit();
                        input.focus();
                    });
                }
            }
        },
        deActivate: function () {
            selectedId = null;
            setSelectedTrackId(selectedId);
            $("#giftMSCC").off('click', clickHandler);
            require('OK/music2/app').off('current-track-changed', onCurrentTrackChange);
        }
    };
})();
